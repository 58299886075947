<template>
	<v-container class="owners">
    <v-btn color="primary" dark elevation="2" fab fixed bottom right :to="{ name: 'Owner', params: { id: 'new' } }">
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-row>
			<v-col cols="12" class="mt-3 mb-3">
        <h2 class="text-h4">Оператори</h2>
      </v-col>
      <v-col cols="12">
        <v-sheet outlined rounded class="owners_table_wrapper">
          <v-data-table :headers="tableHeaders" :items="owners"
                        hide-default-footer disable-filtering multi-sort
                        no-data-text="На жаль, нема жодного оператора." :items-per-page="-1">
            <template v-slot:item.name="{ item }">
              {{ item.name }}
              <span v-if="item.note" class="owners_table_note">{{ item.note }}</span>
            </template>
            <template v-slot:item.phone="{ item }">
              <a :href="`tel:${item.phone}`">{{ item.phone }}</a>
            </template>
            <template v-slot:item.email="{ item }">
              <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn small icon class="mr-2" :to="{ name: 'Owner', params: { id: item.id } }">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn small icon @click.stop="deleteOwnerConfirm(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-sheet>
      </v-col>
    </v-row>
    <AppPageLoader :visible="isPageLoading" />
    <v-dialog v-model="isConfirmDeleteOwnerVisible" max-width="500px">
      <v-card :loading="isOwnerBeingDeleted">
        <v-card-title class="pt-5 pb-5">Видалити цього оператора?</v-card-title>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDeleteOwner">Відмінити</v-btn>
          <v-btn color="red" text @click="deleteOwner">Видалити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { GET_OWNERS, DELETE_OWNER } from '@/store/owners/types';

export default {
  data: () => ({
    ownerToDelete: '',
    isOwnerBeingDeleted: false,
    isConfirmDeleteOwnerVisible: false,
    isPageLoading: false,
    tableHeaders: [
			{
				text: 'Назва',
				value: 'name',
			},
      {
				text: 'Телефон',
				value: 'phone',
			},
      {
				text: 'Email',
				value: 'email',
			},
			{
				text: 'Дія',
				value: 'actions',
        sortable: false,
        width: 100,
			},
    ],
  }),
  methods: {
    async init() {
      this.isPageLoading = true;
      await this.getOwners();

      setTimeout(() => {
        this.isPageLoading = false;
      }, 500);
    },
    async getOwners() {
      await this.$store.dispatch(GET_OWNERS);
		},
    async deleteOwner() {
			this.isOwnerBeingDeleted = true;
			await this.$store.dispatch(DELETE_OWNER, this.ownerToDelete.id);
			await this.getOwners();
			this.closeDeleteOwner();
		},
		closeDeleteOwner() {
			this.isOwnerBeingDeleted = false;
			this.ownerToDelete = null;
			this.isConfirmDeleteOwnerVisible = false;
		},
		deleteOwnerConfirm(owner) {
			this.ownerToDelete = owner;
			this.isConfirmDeleteOwnerVisible = true;
		},
  },
  computed: {
    owners() {
      return this.$store.getters.owners;
    },
  },
  created() {
    this.init();
	},
}
</script>

<style lang="scss" scoped>
.owners {
  max-width: 800px;
}
.owners_table_wrapper {
	margin-bottom: 72px;
}
.owners_table_note {
  display: block;
  font-size: 0.85em;
  font-style: italic;
  opacity: 0.5;
}
</style>
