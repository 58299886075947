var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"owners"},[_c('v-btn',{attrs:{"color":"primary","dark":"","elevation":"2","fab":"","fixed":"","bottom":"","right":"","to":{ name: 'Owner', params: { id: 'new' } }}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-row',[_c('v-col',{staticClass:"mt-3 mb-3",attrs:{"cols":"12"}},[_c('h2',{staticClass:"text-h4"},[_vm._v("Оператори")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"owners_table_wrapper",attrs:{"outlined":"","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.owners,"hide-default-footer":"","disable-filtering":"","multi-sort":"","no-data-text":"На жаль, нема жодного оператора.","items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "),(item.note)?_c('span',{staticClass:"owners_table_note"},[_vm._v(_vm._s(item.note))]):_vm._e()]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("tel:" + (item.phone))}},[_vm._v(_vm._s(item.phone))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("mailto:" + (item.email))}},[_vm._v(_vm._s(item.email))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":"","to":{ name: 'Owner', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteOwnerConfirm(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1),_c('AppPageLoader',{attrs:{"visible":_vm.isPageLoading}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.isConfirmDeleteOwnerVisible),callback:function ($$v) {_vm.isConfirmDeleteOwnerVisible=$$v},expression:"isConfirmDeleteOwnerVisible"}},[_c('v-card',{attrs:{"loading":_vm.isOwnerBeingDeleted}},[_c('v-card-title',{staticClass:"pt-5 pb-5"},[_vm._v("Видалити цього оператора?")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":_vm.closeDeleteOwner}},[_vm._v("Відмінити")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteOwner}},[_vm._v("Видалити")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }